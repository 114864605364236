<template>
  <div class="product">
    <img :src="banner" class="banner" />
    <div class="main">
      <div class="t-a-c title fs24 bold">产品介绍</div>
      <div class="list">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="alCen list-item hand"
          @click="toScheme(item.title)"
        >
          <div>
            {{ item.title }}
          </div>
          <div class="maLfAuto">[ 产品介绍 ]</div>
          <div class="maLf20">
            {{
              item.createTime.length == 10
                ? item.createTime
                : item.createTime.substring(0, 10)
            }}
          </div>
        </div>
      </div>
      <pagination
        class="pagination"
        :total="Number(totalpages)"
        @currentChange="currentChange"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/pagination.vue";
export default {
  name: "product",
  components: {
    Pagination,
  },
  data() {
    return {
      banner: require("../../assets/image/scheme/1.png"),
      list: [
        {
          title: "推进数字乡村建设，助推乡村振兴",
          created_time: "2022-01-29",
        },
        {
          title: "高水平建设“四好农村路”，助推乡村振兴",
          created_time: "2022-01-29",
        },
        {
          title: "全面建设智慧乡村，构建大数据服务平台",
          created_time: "2022-01-29",
        },
        {
          title: "乡村振兴旅游产业大数据设计",
          created_time: "2022-01-29",
        },
        {
          title: "智慧农业大数据平台",
          created_time: "2022-01-29",
        },
        {
          title: "数字乡村农业产业建设解决方案",
          created_time: "2022-01-29",
        },
        {
          title: "智慧城市管理平台",
          created_time: "2022-01-29",
        },
        {
          title: "智慧河长",
          created_time: "2022-01-29",
        },
        {
          title: "四好农村路管养云平台",
          created_time: "2022-01-29",
        },
        {
          title: "智慧公厕",
          created_time: "2022-01-29",
        },
      ],
      totalpages: 1,
    };
  },
  watch: {},
  created() {
    this.getProductDescList();
  },
  methods: {
    toScheme(title) {
      let { href } = this.$router.resolve({
        path: "../scheme",
        query: {
          title,
        },
      });
      window.open(href, "_blank");
    },
    //获取产品介绍列表
    getProductDescList(pageNum = 1) {
      this.request({
        url: this.$api.getProductDescList,
        data: {
          pageNum,
          pageSize: 10,
          searchKey: "",
        },
      }).then((res) => {
        console.log(res, "产品介绍列表");
        if (res.code == 0) {
          this.list = res.data.list;
          this.totalpages = res.data.totalpages;
        }
      });
    },
    currentChange(v) {
      this.getProductDescList(v);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./product.less");
</style>
